<template>
  <div class="device-order-page">
    <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
      <el-tab-pane label="待发货" name="first">
        <div class="content" v-show="authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_NOT_SEND_PAGE_LIST')">
          <div class="btn-area">
            <el-form
              size="small"
              :inline="true"
              :model="notSendParams"
              class="form-inline"
            >
              <el-form-item label="关键字">
                <el-input
                  v-model="notSendParams.searchContent"
                  placeholder="姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="下单日期">
                <el-date-picker
                  @change="handleNotSendDateParamsChange"
                  v-model="notSendSelectDate"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <el-button
                  @click="handleSearchNotSendList"
                  type="primary"
                  icon="el-icon-search"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item label="">
                <el-button
                  type="primary"
                  icon="el-icon-printer"
                  @click="handleNotSendExport"
                  >导出excel</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="list-info">
            <span>订单总数量：{{ notSendData.total }}个</span>
            <span class="mL20">设备总数量：{{ notSendData.instrumentNum }}个</span>
          </div>
          <div class="list-area" ref="notSendTableData">
            <el-table v-loading="notSendLoading" height="100%" :data="notSendData.list" stripe style="width: 100%; min-width: 1400px" :header-cell-style="{ background: '#eef1f6' }">
              <el-table-column align="center" prop="createTimeString" label="下单日期">
              </el-table-column>
              <el-table-column align="center" prop="userNickname" label="订单提交人">
              </el-table-column>
              <el-table-column align="center" prop="userMobile" label="订单提交人电话">
              </el-table-column>
              <el-table-column align="center"  :formatter="formartterPayType" label="支付方式">
              </el-table-column>
              <el-table-column align="center" prop="goodsNum" label="设备数量">
              </el-table-column>
              <el-table-column align="center" prop="rmbNum" label="支付金额">
              </el-table-column>
              <el-table-column align="center" prop="buyPeopleRemarks" label="买家备注">
              </el-table-column>
              <el-table-column align="center" prop="userNickname" label="收件人">
              </el-table-column>
              <el-table-column align="center" prop="receiveAddress" label="收货地址">
              </el-table-column>
              <el-table-column align="center" prop="userMobile" label="收货电话">
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-link
                    v-show="authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_ADD_EXPRESS')"
                    type="primary"
                    :underline="false"
                    @click="handleAddNotSendOrderInfo(scope.row)"
                    >添加单号</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="bottom-area">
            <el-pagination
              background
              @size-change="handleNotSendSizeChange"
              @current-change="handleNotSendCurrentChange"
              :current-page="notSendParams.pageNum"
              :page-size="notSendParams.pageSize"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="notSendData.total"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已发货" name="second">
        <div class="content" v-show="authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_HAVE_SEND_PAGE_LIST')">
          <div class="btn-area">
            <el-form
              size="small"
              :inline="true"
              :model="sendParams"
              class="form-inline"
            >
              <el-form-item label="关键字">
                <el-input
                  v-model="sendParams.searchContent"
                  placeholder="姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="下单日期">
                <el-date-picker
                  @change="handleSendDateParamsChange"
                  v-model="sendSelectDate"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <el-button
                  @click="handleSearchSendList"
                  type="primary"
                  icon="el-icon-search"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item label="">
                <el-button
                  type="primary"
                  icon="el-icon-printer"
                  @click="handleSendExport"
                  >导出excel</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="list-info">
            <span>订单总数量：{{ sendData.total }}个</span>
            <span class="mL20">设备总数量：{{ sendData.instrumentNum }}个</span>
          </div>
          <div class="list-area" ref="sendTableData">
            <el-table v-loading="sendLoading" height="100%" :data="sendData.list" stripe style="width: 100%; min-width: 1400px;" :header-cell-style="{ background: '#eef1f6' }">
              <el-table-column align="center" prop="createTimeString" label="下单日期">
              </el-table-column>
              <el-table-column align="center" prop="userNickname" label="订单提交人">
              </el-table-column>
              <el-table-column align="center" prop="userMobile" label="订单提交人电话">
              </el-table-column>
              <el-table-column align="center" prop="receiveName" label="收件人">
              </el-table-column>
              <el-table-column align="center" prop="receiveMobile" label="收货电话">
              </el-table-column>
              <el-table-column align="center" prop="receiveAddress" label="收货地址">
              </el-table-column>
              <el-table-column align="center"  :formatter="formartterPayType" label="支付方式">
              </el-table-column>
              <el-table-column align="center" prop="goodsNum" label="设备数量">
              </el-table-column>
              <el-table-column align="center" prop="rmbNum" label="支付金额">
              </el-table-column>
              <el-table-column align="center" prop="buyPeopleRemarks" label="备注">
              </el-table-column>
              <el-table-column align="center" prop="orderNo" label="订单编号">
              </el-table-column>
              <el-table-column align="center" prop="sendGoodsNo" label="快递单号">
              </el-table-column>
              <el-table-column align="center" prop="sendGoodsType" label="快递">
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-link
                    v-show="authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_UPDATE')"
                    type="primary"
                    :underline="false"
                    @click="handleEditSendOrder(scope.row)"
                    >修改</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="bottom-area">
            <el-pagination
              background
              @size-change="handleSendSizeChange"
              @current-change="handleSendCurrentChange"
              :current-page="sendParams.pageNum"
              :page-size="sendParams.pageSize"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="sendData.total"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="添加单号" :visible.sync="dialogAddNotSend" width="500px">
      <div>
        <el-form
          ref="addNotSendInfo"
          size="small"
          :model="addNotSendInfo"
          :rules="addNotSendInfoRules"
          label-width="100px"
        >
          <el-form-item label="设备编号" prop="instrumentNo">
            <el-input
              v-model="addNotSendInfo.instrumentNo"
              placeholder="请输入设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input v-model="orderNum" disabled></el-input>
          </el-form-item>
          <el-form-item label="快递名称" prop="sendGoodsType">
            <el-input
              v-model="addNotSendInfo.sendGoodsType"
              placeholder="请输入快递名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="单号" prop="sendGoodsNo">
            <el-input
              v-model="addNotSendInfo.sendGoodsNo"
              placeholder="请输入单号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddNotSend = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmNotSendInfo"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改订单"
      :visible.sync="dialogUpdateSendInfo"
      width="500px"
    >
      <div>
        <el-form
          ref="updateSendInfo"
          size="small"
          :model="updateSendInfo"
          :rules="updateSendInfoRules"
          label-width="100px"
        >
          <el-form-item label="收获地址" prop="receiveAddress">
            <el-input
              v-model="updateSendInfo.receiveAddress"
              placeholder="请输入收获地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="receiveMobile">
            <el-input
              v-model="updateSendInfo.receiveMobile"
              placeholder="请输入电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="收件人" prop="receiveName">
            <el-input
              v-model="updateSendInfo.receiveName"
              placeholder="请输入收件人"
            ></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input v-model="orderNum" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="instrumentNo">
            <el-input
              v-model="updateSendInfo.instrumentNo"
              placeholder="请输入设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="快递名称" prop="sendGoodsType">
            <el-input
              v-model="updateSendInfo.sendGoodsType"
              placeholder="请输入快递名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="快递单号" prop="sendGoodsNo">
            <el-input
              v-model="updateSendInfo.sendGoodsNo"
              placeholder="请输入单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="buyPeopleRemarks">
            <el-input
              type="textarea"
              :rows="2"
              resize="none"
              v-model="updateSendInfo.buyPeopleRemarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogUpdateSendInfo = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handleConfirmSendInfoUpdate"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deviceOrderAdd,
  deviceOrderSendList,
  deviceOrderNotSendList,
  deviceOrderUpdate,
} from "@/api/deviceOrder";
import { tableToExcel } from "@/utils/tool";
import * as dayjs from 'dayjs'
import { mapState } from 'vuex';
export default {
  data() {
    return {
      notSendLoading: false,
      sendLoading: false,
      activeName: "first",
      sendParams: {
        startTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        searchContent: "",
      },
      sendData: {
        list: [],
        total: 0,
        instrumentNum: 0,
      },
      sendSelectDate: [],
      dialogUpdateSendInfo: false,
      updateSendInfo: {
        buyPeopleRemarks: "", // 备注
        instrumentNo: "", // 教学仪设备编号
        receiveAddress: "", // 收货人地址
        receiveMobile: "", // 收货人手机号
        receiveName: "", // 收货人姓名
        sendGoodsNo: "", // 发货物流单号
        sendGoodsType: "", // 发货物流类型名称
        orderId: "",
      },
      updateSendInfoRules: {
        instrumentNo: [
          { required: true, message: "设备编号不能为空", trigger: "change" },
        ],
        receiveAddress: [
          { required: true, message: "收获地址不能为空", trigger: "change" },
        ],
        receiveName: [
          { required: true, message: "收件人不能为空", trigger: "change" },
        ],
        receiveMobile: [
          {
            required: true,
            message: "收货人手机号不能为空",
            trigger: "change",
          },
        ],
        sendGoodsNo: [
          { required: true, message: "快递单号不能为空", trigger: "change" },
        ],
        sendGoodsType: [
          { required: true, message: "快递名称不能为空", trigger: "change" },
        ],
      },
      notSendParams: {
        startTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        searchContent: "",
      },
      notSendData: {
        list: [],
        total: 0,
        instrumentNum: 0,
      },
      notSendSelectDate: [],
      dialogAddNotSend: false,
      orderNum: 1,
      addNotSendInfo: {
        instrumentNo: "",
        sendGoodsNo: "",
        sendGoodsType: "",
        orderId: "",
      },
      addNotSendInfoRules: {
        instrumentNo: [
          { required: true, message: "设备编号不能为空", trigger: "change" },
        ],
        sendGoodsNo: [
          { required: true, message: "单号不能为空", trigger: "change" },
        ],
        sendGoodsType: [
          { required: true, message: "快递名称不能为空", trigger: "change" },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  mounted() {
    this.handleTabClick();
  },
  methods: {
    /* 格式化支付渠道 */
    formartterPayType(row) {
      switch (row.payType) {
        case 1:
          return "支付宝";
        case 2:
          return "微信";
        case 3:
          return "后台新增";
        default:
          return "--";
      }
    },
    /* tab 切换 */
    handleTabClick() {
      if (this.activeName == "first") {
        this.getNotSendList();
      } else if (this.activeName == "second") {
        this.getSendList();
      }
    },
    /* 获取已发货列表 */
    async getSendList() {
      if(!this.authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_HAVE_SEND_PAGE_LIST')){
        return
      }
      try {
        this.sendLoading = true
        const { instrumentNum, pageList: {total, records, pages, current} } = await deviceOrderSendList(
          this.sendParams
        )
        this.sendLoading = false
        this.sendData = {
          list: records,
          total,
          instrumentNum,
        };
        if(current > pages && pages){
          this.sendParams.pageNum = pages 
          this.getSendList()
        }
      } catch (err) {
        this.sendLoading = false
        let msg = err.msg ? err.msg : "获取已发货列表发生异常！";
        console.error("deviceOrderSendList: ", err);
        this.msgError(msg);
      }
    },
    /* 获取待发货列表 */
    async getNotSendList() {
      if(!this.authorities.includes('BACK:BACK_T_ORDER_INSTRUMENT_NOT_SEND_PAGE_LIST')){
        return
      }
      try {
        this.notSendLoading = true
        const { instrumentNum, pageList: { total, records, pages, current } } = await deviceOrderNotSendList(
          this.notSendParams
        );
        this.notSendLoading = false
        this.notSendData = {
          list: records,
          total,
          instrumentNum,
        };
        if(current > pages && pages) {
          this.notSendParams.pageNum = pageNum 
          this.getNotSendList()
        }
      } catch (err) {
        this.notSendLoading = false
        let msg = err.msg ? err.msg : "获取待发货列表发生异常！";
        console.error("deviceOrderNotSendList: ", err);
        this.msgError(msg);
      }
    },
    /* 已发货搜索 */
    handleSearchSendList() {
      this.sendParams.pageNum = 1;
      this.getSendList();
    },
    /* 待发货搜索 */
    handleSearchNotSendList() {
      this.notSendParams.pageNum = 1;
      this.getNotSendList();
    },
    /* 已发货数据导出 */
    handleSendExport() {
      tableToExcel(
        this.$refs.sendTableData,
        `${dayjs().format("YYYY年MM月DD日HH时mm分")}已发货数据统计.xlsx`
      );
    },
    /* 代发货数据导出 */
    handleNotSendExport() {
      tableToExcel(
        this.$refs.notSendTableData,
        `${dayjs().format("YYYY年MM月DD日HH时mm分")}待发货数据统计.xlsx`
      );
    },
    /* 已发货订单编辑 */
    handleEditSendOrder(row) {
      this.orderNum = row.goodsNum
      const {buyPeopleRemarks, instrumentNo,receiveAddress,receiveMobile,receiveName,sendGoodsNo,sendGoodsType, id } = row
      this.updateSendInfo = {
        buyPeopleRemarks: buyPeopleRemarks ? buyPeopleRemarks : '',
        instrumentNo,
        receiveAddress,
        receiveMobile,
        receiveName,
        sendGoodsNo: sendGoodsNo ? sendGoodsNo : '',
        sendGoodsType: sendGoodsType ? sendGoodsType : '',
        orderId: id,
      };
      console.log('this.updateSendInfo: ', row, this.updateSendInfo)
      this.dialogUpdateSendInfo = true
      this.$nextTick(() => {
        if(this.$refs["updateSendInfo"]){
          this.$refs["updateSendInfo"].clearValidate();
        }
      })
    },
    /* 待发货订单编号添加 */
    handleAddNotSendOrderInfo(row) {
      this.dialogAddNotSend = true
      this.orderNum = row.goodsNum
      this.addNotSendInfo = {
        orderId: row.id,
        instrumentNo: "",
        sendGoodsNo: "",
        sendGoodsType: "",
      };
      this.$nextTick(() => {
        if(this.$refs["addNotSendInfo"]){
          this.$refs["addNotSendInfo"].clearValidate();
        }
      })
    },
    /* 已发货信息更新 */
    async handleConfirmSendInfoUpdate() {
      this.$refs["updateSendInfo"].validate(async (valid) => {
        if (valid) {
          if(this._updateSendFlag){
            return
          }
          this._updateSendFlag = true
          try {
            await deviceOrderUpdate(this.updateSendInfo);
            this._updateSendFlag = false
            this.getSendList();
            this.dialogUpdateSendInfo = false;
            this.msgSuccess("保存成功");
          } catch (err) {
            this._updateSendFlag = false
            let msg = err.msg ? err.msg : "修改发生异常";
            console.error("deviceOrderUpdate err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 待发货信息保存 */
    async handleConfirmNotSendInfo() {
      this.$refs["addNotSendInfo"].validate(async (valid) => {
        if (valid) {
          if(this._addNotSendFlag){
            return
          }
          this._addNotSendFlag = true
          try {
            await deviceOrderAdd(this.addNotSendInfo);
            this._addNotSendFlag = false
            this.getNotSendList();
            this.dialogAddNotSend = false;
            this.msgSuccess("保存成功");
          } catch (err) {
            this._addNotSendFlag = false
            let msg = err.msg ? err.msg : "添加单号发生异常";
            console.error("deviceOrderAdd err: ", err);
            this.msgError(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 已发货搜索日期改变 */
    handleSendDateParamsChange() {
      if (this.sendSelectDate) {
        this.sendParams.startTime = this.sendSelectDate[0];
        this.sendParams.endTime = this.sendSelectDate[1];
      } else {
        this.sendParams.startTime = "";
        this.sendParams.endTime = "";
      }
    },
    /* 待发货搜索日期改变 */
    handleNotSendDateParamsChange() {
      if (this.notSendSelectDate) {
        this.notSendParams.startTime = this.notSendSelectDate[0];
        this.notSendParams.endTime = this.notSendSelectDate[1];
      } else {
        this.notSendParams.startTime = "";
        this.notSendParams.endTime = "";
      }
    },
    /* 已发货列表页码改变 */
    handleSendCurrentChange(pageNum) {
      this.sendParams.pageNum = pageNum;
      this.getSendList();
    },
    /* 待发货列表页码改变 */
    handleNotSendCurrentChange(pageNum) {
      this.notSendParams.pageNum = pageNum;
      this.getNotSendList();
    },
    /* 已发货列表单页数量改变 */
    handleSendSizeChange(pageSize) {
      this.sendParams.pageSize = pageSize;
      this.getSendList();
    },
    /* 待发货列表单页数量改变 */
    handleNotSendSizeChange(pageSize) {
      this.notSendParams.pageSize = pageSize;
      this.getNotSendList();
    },
  },
};
</script>
<style lang='scss' scoped>
.device-order-page {
  height: 100%;
  /deep/ {
    .el-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;
      .el-tabs__content {
        flex: 1;
        .el-tab-pane {
          height: 100%;
        }
      }
    }
    .el-table {
      height: 100%;
    }
  }
  .content {
    padding: 0 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .btn-area {
      padding: 10px 0 0 0;
    }
    .list-info {
      height: 40px;
      line-height: 40px;
    }
    .list-area {
      flex: 1;
      overflow-x: auto;
    }
    .bottom-area {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>