import request from "../utils/request";

/* 教学仪订单添加快递 */
export const deviceOrderAdd = (params) => {
    return request.post('/back/t/order/instrument/add/express', params)
}

/* 教学仪已发货列表查询 */
export const deviceOrderSendList = (params) => {
    return request.get('/back/t/order/instrument/have/send/page/list', params)
}

/* 教学仪代发货列表查询 */
export const deviceOrderNotSendList = (params) => {
    return request.get('/back/t/order/instrument/not/send/page/list', params)
}

/* 教学仪订单修改 */
export const deviceOrderUpdate = (params) => {
    return request.post('/back/t/order/instrument/update', params)
}